import { graphql } from 'gatsby'
import { useLayoutEffect } from 'react'
import { useI18n } from '../lib/hooks'

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

const NotFoundPage = ({ ...props }) => {
  const { navigate } = useI18n()

  useLayoutEffect(() => {
    navigate('/')
  }, [])

  return null
}

/*
const NotFoundPage = ({ ...props }) => (
  <MainLayout title="404: Not found">
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </MainLayout>
)*/

export default NotFoundPage
